import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Table, Form } from "react-bootstrap";
import { navigate, useStaticQuery, graphql } from "gatsby";
import $ from "jquery"

import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import { postFormData, api_form_error, putFormData } from "gatsby-theme-starberry-lomondgroup/src/components/forms/api/Api"

import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";

import {PageLinks} from "../common/page-links";

import 'gatsby-theme-starberry-lomondgroup/src/scss/_sitemap.scss';

// markup
const PaymentVerify = (props) => {

    const data = useStaticQuery(graphql`
    query PaymentVerifyQuery{
        glstrapi {
            pages(where: {alias: "payment-verify"}) {
                alias
                content
                title
                seo_meta {
                  description
                  title
                }
            }
        }
    }`)

    var page_data = data.glstrapi?.pages[0];

    const [payment_info, setPaymentInfo] = useState("");

    useEffect(() => {
        const items = typeof window !== 'undefined' && (localStorage.getItem('payment_info') ? JSON.parse(localStorage.getItem('payment_info')) : "");
        if (items?.cartId) {
            setPaymentInfo(items);
            window.scrollTo(0, 0)
        } else{
            navigate(`/${PageLinks.online_payment}/`)
        }
    }, []);

    //console.log("payment_info ==>", payment_info, JSON.parse(localStorage.getItem('payment_info')))

    var seo_meta = {title: "Payment Verification", description:"Payment Verification"}

    const goBack = () =>{
        typeof window !== 'undefined' && window.history.go(-1); return false;
    }

    const handleSubmit = () => {

        //event.preventDefault();

        $(".payment_btn").addClass("disabled")

        let formData = new FormData();

        formData.append('data', JSON.stringify(payment_info));

        postFormData(formData).then(async apiRes => {

            if (apiRes?.success === true) {

                // tracking event
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'formSubmit',
                  'formType': 'form-' + payment_info?.event_tracking,
                  'formId': 'form-' + payment_info?.event_tracking,
                  'formName': payment_info.formname,
                  'formLabel': payment_info?.formname
                });

                localStorage.setItem("payment_info", "")

                //$(".payment_btn").removeClass("disabled")

                setTimeout(() =>{
                    $("#payment_verify")[0].submit();
                    //localStorage.setItem("payment_info", "")
                },1000)
            }
        })

    }

    return (
        <>
        <div className={`search-header property-header`}>
            <Layout seo_meta={seo_meta}>
                <div className="site-map payment_verify">
                    <Container>
                        <Row>
                            <h1>Confirm Your Details</h1>

                            <Form id={"payment_verify"} action="https://secure.worldpay.com/wcc/purchase" method="post">

                                <input type="hidden" name="testMode" value={payment_info?.testMode} /> {/* ==== 100 for test, 0 for live. -- */}

                                <input type="hidden" name="instId" value={payment_info?.instId} />
                                <input type="hidden" name="cartId" value={payment_info?.cartId}/>
                                <input type="hidden" name="amount" value={payment_info?.amount} />
                                <input type="hidden" name="currency" value={payment_info?.currency} />


                                {/* ====  Above mandaory, below optional. -- */}
                                <input type="hidden" name="desc" value={payment_info?.desc} />
                                <input type="hidden" name="name" value={payment_info?.name} />
                                <input type="hidden" name="tel" value={payment_info?.tel}  />
                                <input type="hidden" name="email" value={payment_info?.email} />

                                {/* ====  Display parameters for the payment page. -- */}
                                <input type="hidden" name="hideCurrency" />

                                {/* ====  Below will be returned by World Pay in the transaction result. -- */}
                                <input type="hidden" name="transId" value="" /> {/* ==== WorldPay ID. -- */}
                                <input type="hidden" name="transStatus" value="" /> {/* ==== Y = success, C = cancelled. -- */}
                                <input type="hidden" name="transTime" value="" /> {/* ==== Time of transaction, UNIX timestamp. -- */}
                                <input type="hidden" name="authAmount" value="" /> {/* ==== Amount transaction authorised for in authCurrency. -- */}
                                <input type="hidden" name="authCurrency" value="" /> {/* ==== Currency used for transaction. -- */}
                                <input type="hidden" name="rawAuthMessage" value="" /> {/* ==== Text from bank. -- */}
                                
                                {payment_info?.cartId &&
                                    <Table striped bordered responsive width={"100%"} className="payment_info" cellPadding={20}>
                                        <tr>
                                            <td className="label">Name</td>
                                            <td className="label">{payment_info?.name}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Payment Type</td>
                                            <td className="label">{payment_info?.payment_type}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Rental Address</td>
                                            <td className="label">{payment_info?.property_address}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Email Address</td>
                                            <td className="label">{payment_info?.email}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Telephone No</td>
                                            <td className="label">{payment_info?.tel}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Amount</td>
                                            <td className="label">£{payment_info?.amount}</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Stading Order Ref</td>
                                            <td className="label">{payment_info?.cartId}</td>
                                        </tr>
                                    </Table>
                                }

                                <div className="btn_wrapper">
                                    <a className="btn btn-outline" onClick={goBack}>Change Details</a>

                                    <input className="btn btn-primary payment_btn" type={"button"} value={"Make Payment"} onClick={handleSubmit}/>
                                </div>
                            </Form>
                            
                            {page_data?.content && 
                            <section className="customer text_block none">
                                <div className="video-content full_wdth">
                                    <ContentModule Content={page_data.content}/>
                                </div>
                            </section>
                            }
                        </Row>
                    </Container>
                </div>
            </Layout>
        </div>
        </>
    )
}

export default PaymentVerify

